import React from "react"
import { Link as LinkComponent } from "gatsby"
import styled from "styled-components"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import { Row, Col } from "./layout/Grid"
import Container from "./layout/Container"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
import LazyImage from "./helpers/LazyImage"

// Icons
import Explore from "../components/icons/explore"
import Launch from "../components/icons/launch"
import Shapes from "../components/icons/shapes"
import MapShapes from "../components/icons/map"

interface IProps {
  link: {
    url: string
  }
  client: {
    html: string
  }
  title: string[]
  services: string[]
  heroImage: {
    url: string
    alt?: string
  }
  showExploreIcon: boolean
  showLaunchIcon: boolean
  showMapIcon: boolean
  showShapeIcon: boolean
}

const CasePreview = React.memo(
  ({
    link,
    client,
    title,
    heroImage,
    showExploreIcon,
    showLaunchIcon,
    showMapIcon,
    showShapeIcon,
  }: IProps) => (
    <Wrapper>
      <Container>
        {link && link.url && (
          <LinksWrapper>
            <AnimatedScrollWrapper>
              <Row>
                <Col>
                  <Link to={link.url} aria-label={`Go to next case: ${title}`}>
                    Next case
                  </Link>
                </Col>
              </Row>
            </AnimatedScrollWrapper>
          </LinksWrapper>
        )}
        <Content>
          <Row></Row>
        </Content>

        <Row>
          <Col xxs={8} m={12} mOffset={2}>
            <AnimatedScrollWrapper>
              <IconsImageContainer>
                <Col xxs={16}>
                  <Text>
                    <p>{client.html.replace(/<[^>]+>/g, "")}</p>
                    <p>{title}</p>
                  </Text>
                </Col>
                <Col xxs={16} m={16} mOffset={0}>
                  <IconsWrapper>
                    {showMapIcon && (
                      <IconContainer>
                        <p>Map</p>
                        <MapShapes />
                      </IconContainer>
                    )}
                    {showExploreIcon && (
                      <IconContainer>
                        <p> Expore</p>
                        <Explore />
                      </IconContainer>
                    )}
                    {showShapeIcon && (
                      <IconContainer>
                        <p>Shape</p>
                        <Shapes />
                      </IconContainer>
                    )}
                    {showLaunchIcon && (
                      <IconContainer>
                        <p> Launch</p>
                        <Launch />
                      </IconContainer>
                    )}
                  </IconsWrapper>
                </Col>
              </IconsImageContainer>
              {heroImage && heroImage.url && (
                <ImageWrapper>
                  <LazyImage
                    src={heroImage.url}
                    alt={heroImage.alt}
                    linkTo={link.url}
                    mode="img"
                  />
                </ImageWrapper>
              )}
            </AnimatedScrollWrapper>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
)

// Main styles
const Wrapper = styled.div`
  position: relative;
  padding-top: var(--base-column-size);
  padding-bottom: var(--base-column-size);
  background-color: ${colors.black};
  padding-top: 8px;

  ${mq.from.M`
    padding-bottom: calc(2 * var(--base-column-size));
  `}
`

const LinksWrapper = styled.div``

const Text = styled.div`
  color: white;
  p:first-child {
    margin-bottom: 3px;
  }
`

const Link = styled(LinkComponent)`
  ${textStyles.hyperlink};
  font-size: 36px;
  ${mq.from.M`
  font-size: 32px;
  `}
  ${mq.from.XXL`
  font-size: 64px;
  `}
  color: ${colors.white};
`

const Content = styled.div`
  position: relative;
  margin-bottom: var(--base-column-size);
  color: white;
`
const IconsImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  ${mq.from.XL`
  flex-direction: row;
`}
`
const IconContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  background: #1e1e1e;
  svg {
    fill: #ffffff;
  }

  p {
    color: white;
    margin-left: 8px;
    margin-right: 32px;

    ${mq.from.L`
    margin-left: 16px;
    margin-right: 3px;
    `}
  }
`

const IconsWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 10%;
  width: 100%;
  overflow-y: scroll;

  ${mq.from.M`
  overflow-y: unset;
`}
  ${mq.from.XL`
  justify-content: right;`}
`

const ImageWrapper = styled.div`
  line-height: 0;
`
export default CasePreview
