import React from "react"

export const MapShapes = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 448 448"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M224 448C347.712 448 448 347.712 448 224C448 100.288 347.712 0 224 0C100.288 0 0 100.288 0 224C0 347.712 100.288 448 224 448ZM380.8 67.2H67.2V380.8H380.8V67.2Z"
      />
    </svg>
  )
}

export default MapShapes
