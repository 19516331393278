import React from "react"

export const Shapes = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 448 448"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M65.6081 65.5813C96.935 34.2672 136.848 12.942 180.3 4.30244C223.751 -4.33711 268.79 0.0970092 309.721 17.0441C350.652 33.9912 385.636 62.69 410.249 99.5115C434.863 136.333 448 179.623 448 223.908L224 223.908L65.6081 65.5813Z" />
      <path d="M382.392 382.235C351.065 413.549 311.152 434.875 267.7 443.514C224.249 452.154 179.21 447.72 138.279 430.772C97.3483 413.825 62.3643 385.127 37.7508 348.305C13.1374 311.484 9.50605e-06 268.193 0 223.908L224 223.908L382.392 382.235Z" />
    </svg>
  )
}

export default Shapes
