import React from "react"
import { graphql } from "gatsby"

// Utils
import {
  formatPrismicData,
  getTextLines,
} from "../../utils/helpers/prismicHelpers"

// Components
import CaseTemplate from "./caseTemplateView"

const ConnectedCasePage = ({ data }: any) => {
  if (!data.caseStudy) {
    return
  }

  const rawData = data.caseStudy.data
  const nextCaseData = rawData.nextCase.document[0].data

  // Sorry for the duplication, not worth it making a helper function just for this :(
  const formattedNextCase = {
    ...nextCaseData,
    link: {
      url: `/case/${rawData.nextCase.uid}`,
    },
    title: getTextLines(nextCaseData.title.raw),
    services: nextCaseData.services.map(
      (service: any) => service.name.document[0].data.name
    ),
  }

  const formattedData = {
    ...rawData,
    title: getTextLines(rawData.title.raw),
    services: rawData.services.map(
      (service: any) => service.name.document[0].data.name
    ),
    nextCase: formattedNextCase,
  }

  const slices = rawData.slices
    .filter((slice: any) => slice.type && slice)
    .map((slice: any) => formatPrismicData(slice))

  return <CaseTemplate data={formattedData} slices={slices} />
}

export const query = graphql`
  query loadPrismicCaseStudy($slug: String!) {
    caseStudy: prismicCaseStudy(uid: { eq: $slug }) {
      id
      slug: uid
      data {
        showExploreIcon: show_explore_icon
        showLaunchIcon: show_launch_icon
        showMapIcon: show_map_icon
        showShapeIcon: show_shape_icon
        metaTitle: meta_title
        metaDescription: meta_description
        client {
          html
        }
        title {
          raw {
            text
          }
        }
        services {
          name: service_name {
            document {
              data {
                name
              }
            }
          }
        }
        heroVideo: hero_video {
          url
        }
        leftColumnTitle: left_column_title
        leftColumnCopy: left_column_copy {
          html
        }
        rightColumnTitle: right_column_title
        rightColumnCopy: right_column_copy {
          html
        }
        nextCase: next_case {
          id
          uid
          document {
            data {
              showExploreIcon: show_explore_icon
              showLaunchIcon: show_launch_icon
              showMapIcon: show_map_icon
              showShapeIcon: show_shape_icon
              client {
                html
              }
              title {
                raw {
                  text
                }
              }
              services {
                name: service_name {
                  document {
                    data {
                      name
                    }
                  }
                }
              }
              heroImage: hero_image {
                alt
                url
              }
            }
          }
        }
        slices: body {
          ... on PrismicCaseStudyBodyTextBlock {
            type: slice_type
            id
            primary {
              text_block_title
              text_block_text {
                html
              }
              text_block_alignment
              text_block_has_spacing
            }
          }
          ... on PrismicCaseStudyBodyTextColumns {
            type: slice_type
            id
            primary {
              text_columns_left_column {
                html
              }
              text_columns_right_column {
                html
              }
            }
          }
          ... on PrismicCaseStudyBodyTextMedia {
            type: slice_type
            id
            primary {
              text_media_title {
                raw {
                  text
                }
              }
              text_media_copy {
                html
              }
              text_media_media {
                kind
                url
                alt: name
              }
              text_media_media_alignment
              text_media_media_padding
              text_media_has_spacing
            }
          }
          ... on PrismicCaseStudyBodyImage {
            type: slice_type
            id
            primary {
              image {
                alt
                url
              }
              image_has_spacing
            }
          }
          ... on PrismicCaseStudyBodyVideo {
            type: slice_type
            id
            primary {
              link {
                url
              }
            }
          }
          ... on PrismicCaseStudyBodyQuote {
            type: slice_type
            id
            primary {
              quote_copy {
                html
              }
              quote_author
              quote_size
            }
          }
          ... on PrismicCaseStudyBodyFacts {
            type: slice_type
            id
            primary {
              facts_title
            }
            factItems: items {
              fact_title
              fact_copy
              fact_source_link {
                url
                target
              }
              fact_source_name
            }
          }
          ... on PrismicCaseStudyBodyContactBanner {
            id
            type: slice_type
            primary {
              contact_banner_details {
                html
              }
              contact_banner_title
            }
          }
        }
      }
    }
  }
`

export default ConnectedCasePage
