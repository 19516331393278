import React from "react"

export const Launch = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 448 448"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M224.156 447.634C347.78 447.634 447.997 347.428 447.997 223.817C447.997 100.207 347.78 0.000346313 224.156 0V447.634Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.0703 447.633C84.0716 447.633 84.0728 447.633 84.0741 447.633C161.437 447.633 224.151 384.925 224.151 307.571C224.151 230.218 161.437 167.51 84.0741 167.51C84.0728 167.51 84.0716 167.51 84.0703 167.51V447.633Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 447.637C46.6666 447.635 84.4967 409.807 84.4967 363.146C84.4967 316.484 46.6666 278.656 0 278.654V447.637Z"
      />
    </svg>
  )
}

export default Launch
