import React from "react"

export const Explore = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 448 448"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M112 111.954V223.908H0C0 194.216 11.8 165.74 32.804 144.745C53.8081 123.749 82.2958 111.954 112 111.954Z" />
      <path d="M224 0V111.954H112C112 82.2621 123.8 53.7861 144.804 32.7906C165.808 11.7951 194.296 0 224 0Z" />
      <path d="M112 0V111.954H0C0 82.2621 11.8 53.7861 32.804 32.7906C53.8081 11.7951 82.2958 0 112 0Z" />
      <path d="M448 223.908H336V111.954C365.704 111.954 394.192 123.749 415.196 144.745C436.2 165.74 448 194.216 448 223.908Z" />
      <path d="M336 111.954H224V0C253.704 0 282.192 11.7951 303.196 32.7906C324.2 53.7861 336 82.2621 336 111.954Z" />
      <path d="M448 111.954H336V1.06768e-06C365.704 1.06768e-06 394.192 11.7951 415.196 32.7906C436.2 53.7861 448 82.2621 448 111.954Z" />
      <path d="M0 223.908H112V335.862C82.2958 335.862 53.8081 324.067 32.804 303.072C11.8 282.076 0 253.6 0 223.908Z" />
      <path d="M112 335.862H224V447.817C194.296 447.817 165.808 436.021 144.804 415.026C123.8 394.031 112 365.555 112 335.862Z" />
      <path d="M0 335.862H112V447.817C82.2958 447.817 53.8081 436.021 32.804 415.026C11.8 394.031 0 365.555 0 335.862Z" />
      <path d="M336 335.862V223.908H448C448 253.6 436.2 282.076 415.196 303.072C394.192 324.067 365.704 335.862 336 335.862Z" />
      <path d="M224 111.954V223.908H112C112 194.216 123.8 165.74 144.804 144.745C165.808 123.749 194.296 111.954 224 111.954Z" />
      <path d="M336 223.908H224V111.954C253.704 111.954 282.192 123.749 303.196 144.745C324.2 165.74 336 194.216 336 223.908Z" />
      <path d="M112 223.908H224V335.862C194.296 335.862 165.808 324.067 144.804 303.072C123.8 282.076 112 253.6 112 223.908Z" />
      <path d="M224 335.862V223.908H336C336 253.6 324.2 282.076 303.196 303.072C282.192 324.067 253.704 335.862 224 335.862Z" />
      <path d="M224 447.817V335.862H336C336 365.555 324.2 394.031 303.196 415.026C282.192 436.021 253.704 447.817 224 447.817Z" />
      <path d="M336 447.817V335.862H448C448 365.555 436.2 394.031 415.196 415.026C394.192 436.021 365.704 447.817 336 447.817Z" />
    </svg>
  )
}

export default Explore
