import React, { useContext, useEffect } from "react"
import styled from "styled-components"

// Types
import { ISlice, IOnOffType } from "../../types/"

// Styling
import { mq } from "../../styles/utils/mq"
import colors from "../../styles/colors"
// Utils
import PageContext from "../../utils/context/PageContext"

// Components
import { renderSlice } from "../../components/helpers/slice"
import { Row, Col } from "../../components/layout/Grid"
import SEO from "../../components/layout/SEO"
import Container from "../../components/layout/Container"
import AnimatedScrollWrapper from "../../components/animation/AnimatedScrollWrapper"
import CasePreview from "../../components/CasePreview"

// Config
import { config as pageTransitionsConfig } from "../../components/pageTransitions/config"
import Explore from "../../components/icons/explore"
import Launch from "../../components/icons/launch"
import Shapes from "../../components/icons/shapes"
import MapShapes from "../../components/icons/map"
import TryUs from "../../components/TryUs"

interface IProps {
  data: {
    metaTitle?: string
    metaDescription?: string
    client: {
      html: string
    }
    title: string[]
    services: string[]
    heroVideo: {
      url: string
    }
    leftColumnTitle: string
    leftColumnCopy: {
      html: string
    }
    rightColumnTitle: string
    rightColumnCopy: {
      html: string
    }
    showExploreIcon: boolean
    showLaunchIcon: boolean
    showMapIcon: boolean
    showShapeIcon: boolean
    nextCase: {
      link: {
        url: string
      }
      client: {
        html: string
      }
      title: string[]
      services: string[]
      heroImage: {
        url: string
        alt?: string
      }
      showExploreIcon: boolean
      showLaunchIcon: boolean
      showMapIcon: boolean
      showShapeIcon: boolean
    }
  }
  slices: ISlice[]
}

const CaseTemplate = React.memo(({ data, slices }: IProps) => {
  const page = useContext(PageContext)
  const plainClientCase = data.client.html.replace(/<[^>]+>/g, "")

  useEffect(() => {
    page.updateColor(colors.white)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  return (
    <Wrapper>
      <SEO
        title={`${data.metaTitle || data.title} - Case`}
        description={data.metaDescription}
      />

      <Header>
        <Container>
          {data.client && data.client.html && (
            <Client>
              <AnimatedScrollWrapper>
                <div dangerouslySetInnerHTML={{ __html: data.client.html }} />
                <p dangerouslySetInnerHTML={{ __html: data.title[0] }} />
              </AnimatedScrollWrapper>
            </Client>
          )}
        </Container>
      </Header>

      <Content>
        {data.heroVideo && data.heroVideo.url && (
          <VideoWrapper>
            <Container>
              <Row>
                <Col xxs={8} m={16} mOffset={0}>
                  <AnimatedScrollWrapper>
                    <Video src={data.heroVideo.url} autoPlay loop muted>
                      <p>
                        If you are reading this, it is because your browser does
                        not support the HTML5 video element.
                      </p>
                    </Video>
                  </AnimatedScrollWrapper>
                </Col>
              </Row>
            </Container>
          </VideoWrapper>
        )}
        <Row>
          <Container>
            <AnimatedScrollWrapper>
              <IconsImageContainer>
                <Col xxs={16} m={4}>
                  <Text>
                    <p>What we did for {plainClientCase}</p>
                  </Text>
                </Col>
                <Col xxs={16} m={8}>
                  <IconsWrapper>
                    {data.showMapIcon && (
                      <IconContainer>
                        <p>Map</p>
                        <MapShapes />
                      </IconContainer>
                    )}
                    {data.showExploreIcon && (
                      <IconContainer>
                        <p> Expore</p>
                        <Explore />
                      </IconContainer>
                    )}
                    {data.showShapeIcon && (
                      <IconContainer>
                        <p>Shape</p>
                        <Shapes />
                      </IconContainer>
                    )}
                    {data.showLaunchIcon && (
                      <IconContainer>
                        <p> Launch</p>
                        <Launch />
                      </IconContainer>
                    )}
                  </IconsWrapper>
                </Col>
              </IconsImageContainer>
            </AnimatedScrollWrapper>
          </Container>
        </Row>
        {slices &&
          slices.length &&
          slices.map((slice: ISlice) => {
            const hasSpacing = slice.data.hasSpacing

            const props = {
              // Assign a default
              isSpaced: "on" as IOnOffType,
              // Override if condition passes
              ...(hasSpacing === "off" && {
                isSpaced: slice.data.hasSpacing,
              }),
            }

            if (slice.type === "video") {
              slice.data = { ...slice.data, hasControls: false }
            }

            return (
              <Section key={slice.id} {...props}>
                {renderSlice(slice)}
              </Section>
            )
          })}
        <Row>
          <Col m={12} mOffset={2}>
            <StyledTryUs
              page={page}
              title="Experience the difference!"
              linkLabel="Get in touch!"
              blockTheme="accent"
              transparent={true}
            />
          </Col>
        </Row>
        {data.nextCase && <CasePreview {...data.nextCase} />}
      </Content>
    </Wrapper>
  )
})

// Utility styles
const Section = styled.section<{ isSpaced?: IOnOffType }>`
  margin-bottom: var(--base-column-size);

  ${mq.from.L`
    margin-bottom: ${(props: { isSpaced?: IOnOffType }) =>
      props.isSpaced === "on" ? "calc(100vh / 4)" : "0"};
  `}

  &:last-of-type {
    margin-bottom: 0;
  }
`

// Main styles
const Wrapper = styled.article`
  padding-top: var(--base-column-size);
`

// Header
const Header = styled.header`
  position: relative;
  margin-bottom: 0px;
`

const Client = styled.div`
  margin-bottom: 8px;
  ${mq.from.M`
  margin-bottom: 8px;
  `}
  ${mq.from.XXL`
  margin-bottom: 8px;
  `}
  p {
    margin-bottom: 0;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (768 - 320)));
    ${mq.from.M`
      font-size: calc(24px + (40 - 24) * ((100vw - 768px) / (1280 - 768)));
      `}
    ${mq.from.XL`
      font-size:calc(52px + 28 * ((100vw - 1280px) / 640));
      `};

    /* Force the long dash on the first client line */
  }
`
// Content
const Content = styled.div``

const VideoWrapper = styled.div`
  margin-bottom: 8px;
`

const Video = styled.video`
  display: block;
  width: 100%;
`
const Text = styled.div`
  padding-right: 32px;
`

const IconsImageContainer = styled.div`
  margin-bottom: var(--base-column-size);
  display: flex;
  flex-direction: column;

  ${mq.from.M`
  display: flex;
  flex-direction: row;
 
`}
`

const IconsWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 10%;
  background-color: ${colors.white};
  overflow-y: scroll;
  width: 100%;
  margin-bottom: var(--base-column-size);

  ${mq.from.M`
    overflow-y: unset;
`}
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  p {
    margin-left: 8px;
    margin-right: 32px;

    ${mq.from.L`
    margin-left: 16px;
    margin-right: 49px;
`}
  }
`

const StyledTryUs = styled(TryUs)`
  margin-top: 150px;
`


export default CaseTemplate
